.info-page {
  padding-left: 25px;
  padding-top: 80px;
  height: 100%;
  width: 90%;
  overflow: auto;
  font-family: 'Archivo-Bold', Arial;
  font-weight: bold;

  .header {
    color: black;
    line-height: 1;
    font-size: 46px;
    text-align: left; /* Aligns text to the left */
  }

  .description {
    color: black;
    line-height: 1;
    font-size: 32px;
    padding-left: 8px;
    text-align: left; /* Aligns text to the left */
  }

  .info-list {
    color: black;
    font-size: 22px;
    padding-bottom: 100px;
    text-align: left; /* Aligns text to the left */
  }
}

@media screen and (max-width: 1000px) {
  .info-page {
    padding-top: 90px;

    .header {
      font-size: 28px;
    }

    .description {
      font-size: 14px;
    }

    .info-list {
      font-size: 12px;
      padding-bottom: 200px;
    }
  }
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.main-page {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  height: 100vh; // Set the height of the main page to the full viewport height
  font-family: 'Archivo-Bold', Arial;
  font-weight: bold;
  overflow: hidden;

  .header {
    width: 100%;
  }

  .header-title {
    color: black;
    line-height: 0;
    font-size: 36px;
    padding-left: 8px;
  }

  .header-note {
    color: black;
    font-size: 18px;
    padding-left: 8px;

    .contact-link {
      color: blue;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .boxes {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    padding-bottom: 400px;

    .boxes-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 2px;
      overflow-y: auto;

      // Set a min-height to ensure there's space for scrolling
      min-height: calc(
        100vh - 216px
      ); // Adjust based on your footer height and padding
    }
  }

  .box {
    position: relative;
    flex: 1 1 20%;
    height: 300px;
    overflow: hidden;
    border-radius: 16px;
    min-width: calc(20% - 10px);
    max-width: calc(100% - 25px);
    border: 4px solid black;
    background-color: lightcoral;
    opacity: 0;
    animation: fadeInAnimation 0.5s forwards;

    .box-header {
      height: 24%;
      padding-left: 8px;
    }

    .title {
      color: rgb(0, 0, 0);
      line-height: 0;
      font-size: 28px;
    }

    .selection-label {
      color: white;
      line-height: 0;
      font-size: 20px;
      -webkit-text-stroke-color: black;
      -webkit-text-stroke-width: 0.6px;
      padding-bottom: 2px;
    }

    .stats-label {
      color: white;
      line-height: 0;
      font-size: 15px;
      -webkit-text-stroke-color: black;
      -webkit-text-stroke-width: 0px;
    }

    @keyframes blink {
        0%, 50%, 100% {
          opacity: 1;
        }
        25%, 75% {
          opacity: 0;
        }
      }
      
      .blink-dots {
        animation: blink 1.5s infinite; /* Adjust the timing as per your preference */
      }

    .buttons-container {
      overflow: auto;
      justify-content: center;
      height: 67%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .box-image {
        top: 98px;
        position: absolute;
        width: 100%;
        height: 80%;
        object-fit: cover;
        border: 2.5px solid black;
        background-color: #26bbff;
      }

      .button-box {
        z-index: 1;
        border-radius: 50%;
        padding: 2px;
        padding-top: 5px;
        min-width: calc(20% - 10px);
        max-width: calc(100% - 25px);
      }

      .button-image {
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 25%;
        border: 5px solid lightcoral;
        padding: 2px;
        transition: background-color 0.5s ease;
      }

      .button-icon {
        width: 50px;
        height: auto;
      }

      .button-image:hover {
        transition: 0.8s;
        background-color: white;
      }

      .button-image:active {
        transition: 0s;
        box-shadow: 0 4px rgb(74, 74, 79);
        transform: translateY(4px);
      }

      .button-image.selected {
        background-color: yellow;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure the footer is above other content */
  text-align: center;
  font-family: 'Archivo-Bold', Arial;
  font-weight: bold;

  .greeting,
  .probability-label,
  .small-talk-comparison {
    color: black;
    line-height: 0.9;
    margin: 0;
    padding: 5px 0;
  }

  .greeting {
    font-size: 24px;
  }

  .probability-label {
    font-size: 18px;
  }

  .combined-probability {
    font-size: 24px;
  }

  .small-talk-comparison {
    font-size: 18px;
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: scale(0.0001);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@for $i from 1 through 17 {
  .box:nth-child(#{$i}) {
    animation: fadeInAnimation #{1s} forwards;
    animation-delay: ($i + 4) * 0.25s;
  }
}

@media screen and (max-width: 1000px) {
  .main-page {
    padding-top: 90px;

    .header {
      padding-top: 6px;
    }

    .header-title {
      font-size: 14px;
    }

    .header-note {
      font-size: 11px;
    }

    .boxes-container {
      padding-bottom: 50px;

      .box {
        flex: 1 1 33%;
        border: 3px solid black;
        height: 190px;

        .box-header {
          height: 24%;
          padding-left: 8px;
        }

        .title {
          font-size: 17px;
        }

        .selection-label {
          font-size: 13px;
          -webkit-text-stroke-width: 0.25px;
        }

        .stats-label {
          font-size: 9px;
        }

        .box-image {
          top: 60px;
        }

        .button-icon {
          width: 33px;
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the footer is above other content */
    text-align: center;

    .greeting,
    .probability-label,
    .small-talk-comparison {
      color: black;
      line-height: 0.9;
      margin: 0;
      padding: 5px 0;
    }

    .greeting {
      font-size: 14px;
      font-weight: 400;
    }

    .probability-label {
      font-size: 12px;
      font-weight: 400;
    }

    .combined-probability {
      font-size: 15px;
      font-weight: 700;
    }

    .small-talk-comparison {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

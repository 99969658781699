.nav-bar {
  background: #181818;
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 10px;
  font-family: "Archivo-Bold", Arial;
  font-weight: bold;

  .nav-title {
    display: block;
    position: absolute;
    font-size: 32px;
    color: #fff;
    margin-left: 40px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    a {
      font-size: 40px;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;
      padding: 20px;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #00ffbb;
      }
    }
  }

  .logo {
    position: absolute;
    right: 5%;
    padding: 10px 30px;

    img {
      display: block;
      margin: 8px auto;
      width: 35px;
      height: auto;
    }
  }
}

@media screen and (max-width: 1000px) {
  .nav-bar {
    .nav-title {
      font-size: 14px;
      margin-left: 16px;
    }

    .buttons {
      padding-left: 40px;

      a {
        font-size: 26px;
        padding: 8px;
      }
    }

    .logo {
      right: 2%;

      img {
        padding-top: 4px;
        width: 30px;
      }
    }
  }
}

html {
    font-size: 62.5%;
  }
  
  
  body {
    margin: 0;
    font-family: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
    color: #444;
    background: #dbdbdb;
    overflow: hidden;
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  